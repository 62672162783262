import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import axios from "axios";

import '@/assets/css/home.less'
import '@/assets/css/base.less'

import '@/assets/font/iconfont.css'

// import 'amfe-flexible'
// import 'amfe-flexible/index.js';

import MFooter from "@/components/m-footer.vue";
Vue.component('MFooter', MFooter)
// 访客员工首页
import Vfooter from "@/components/visitor/v-footer";
Vue.component('Vfooter', Vfooter)
// // 访客首页
// import Ufooter from "@/components/visitor/u-footer";
// Vue.component('Ufooter', Ufooter)


import './components/loading/index.css';
import Loading from './components/loading/index';
Vue.use(Loading);

import { setopenID, getopenID,removeopenID } from '@/utils/auth'

function geturl(name,url){
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || ""
}


router.beforeEach(async (to, from, next) => {

 
  // 判断是否是访客路由 公众号/企业微信
  const href = window.location.href
  if (href.includes('visitor') ){
      console.log('公众号');
      // setopenID('oAZPCw6MA9gIP476n8e89H9E6LWQ');
      const openID = getopenID()
      // console.log(openID)
      
      if (openID) { // token设置 直接跳转 无则去换取
        next()  
        // alert(12121)
       } else {
        const service_appid='wxd0d723703765e149'; 
          // alert(appid)
        const redirect_uri=encodeURIComponent(window.location.href);
        const code = geturl('code',window.location.href) // 截取url上的code ,可能没有,则返回''空字符串
        if (code == null || code == '') { // 如果没有code，则去请求
          // alert("回调1");
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${service_appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        } else {
    
          // alert(code);
    
          let param={
            code:code
          }
          // alert(process.env.VUE_APP_BASE_API+'/api/pe/user/login')
          axios.get(process.env.VUE_APP_BASE_API+'/api/fw/user/login', {params: param}).then(function (res) {
            // alert(222)
            if(res.data.code==200){
                // console.log(res)
                setopenID(res.data.data)
                next()
            }else if(res.data.code==401){ // token过期重新刷新请求
              removeopenID()
              window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
              // alert(res.data.message)
            } else {
              alert(res.data.msg)
            }
          })
    
        }
    
       }

  }else{
      
      // setopenID('ofU1pwjitFaA6hHDliKGfub_W_f8');
      // 企业微信
      const openID = getopenID()
      // alert(openID)
      
      if (openID) { // token设置 直接跳转 无则去换取
        next()  
        // alert(12121)
       } else {
        const appid='wx0b55f18584de94e9'; 
          // alert(appid)
        const redirect_uri=encodeURIComponent(window.location.href);
        const code = geturl('code',window.location.href) // 截取url上的code ,可能没有,则返回''空字符串
        if (code == null || code == '') { // 如果没有code，则去请求
          // alert("回调1");
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        } else {
    
          // alert(code);
    
          let param={
            code:code
          }
          // alert(process.env.VUE_APP_BASE_API+'/api/pe/user/login')
          axios.get(process.env.VUE_APP_BASE_API+'/api/pe/user/login', {params: param}).then(function (res) {
            // alert(222)
            if(res.data.code==200){
                // console.log(res)
                setopenID(res.data.data)
                next()
            }else if(res.data.code==401){ // token过期重新刷新请求
              removeopenID()
              window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
              // alert(res.data.message)
            } else {
              alert(res.data.msg)
            }
          })
    
        }
    
       }
  }
  

	
 
})

//适配苹果底部
Vue.directive('isIphoneX', {
  bind: function (el, binding) {
      const u = navigator.userAgent;
      const _local = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      let isIphoneX = false
      if (_local && window.screen.height) {
          isIphoneX = window.screen.height > 750 ? true : false
      }
      if (isIphoneX) {
          if (binding.value === 'margin') {
              console.log('use margin')
              el.setAttribute('style', 'margin-bottom: 8vw !important;')
          } else if (binding.value === 'height') {
              el.setAttribute(
                  'style',
                  ` padding-bottom: 15vh !important;min-height: 85vh !important;`
              )
          } else {
              el.setAttribute('style', 'padding-bottom: 8vw !important;')
          }
      } else {
          if (binding.value === 'height') {
              el.setAttribute(
                  'style',
                  ` padding-bottom: 11vh !important;min-height: 89vh !important;`
              )
          }
      }
  },
  update: function (el, binding) { }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



