<template>
  <div>
    <van-tabbar route>
      <van-tabbar-item replace to="/home" icon="wap-home-o">首页</van-tabbar-item>
      <!-- <van-tabbar-item replace to="#" icon="setting-o">维修</van-tabbar-item> -->
      <van-tabbar-item replace to="/mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  name: "m-footer",
  data() {
    // 这里存放数据
    return {}
  },
  // import 引入的组件需要注入到对象中才能使用
  
}
</script>

<style scoped>
</style>
