import storage from '@/assets/js/storage'


export function getopenID() {
  return storage.get('openID')
}

export function setopenID(openid) {
  storage.set('openID',openid)
}

export function removeopenID() {
  storage.remove('openID')
}

//首页登录
// export function auth_required() {
//   // storage.set("openID", res.data.ding_user_id);
//   // storage.set("openID", "01055100123421396618");
//   // storage.set("openID", "0117122119");

//   // storage.set("user", JSON.stringify(
//   //   {"fpsn_name":"吴凯祥",
//   // "fpsn_num":"01055100123421396618",
//   // "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzQ3MDMxOTh9.tpa8wpy5oXKa_oW8nK5l9jpjR71IxY9D1g9CCVHCllg"}));

// }

