import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'home',
  },
  {
    path: '/orderList',
    name: 'orderList',
    meta: {
      title: '订餐页面',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/order/index.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      title: '订餐详情页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/order/orderDetail.vue')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: {
  //     title: '登陆页面',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  // },
  {
    path: '/myOrder',
    name: 'myOrder',
    meta: {
      title: '我的订单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrder/myOrder.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    meta: {
      title: '付款成功',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/order/paySuccess.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '生活区首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue')
  },
  {
    path: '/water',
    name: 'water',
    meta: {
      title: '水费查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/water.vue')
  },
  {
    path: '/elect',
    name: 'elect',
    meta: {
      title: '电费查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/elect.vue')
  },
  {
    path: '/stay',
    name: 'stay',
    meta: {
      title: '住宿费查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/stay.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    meta: {
      title: '我的',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/index.vue')
  },
  {
    path: '/carelist',
    name: 'carelist',
    meta: {
      title: '我的卡券',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/care/list.vue')
  },
  {
    path: '/caredetail',
    name: 'caredetail',
    meta: {
      title: '卡券明细',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/care/detail.vue')
  },
  {
    path: '/carehistory',
    name: 'carehistory',
    meta: {
      title: '卡券历史',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/care/history.vue')
  },
  {
    path: '/questionList',
    name: 'questionList',
    meta: {
      title: '调查问卷',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/question/index.vue')
  },
  {
    path: '/formQuestion',
    name: 'formQuestion',
    meta: {
      title: '调查问卷',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/question/formQuestion.vue')
  },
  {
    path: '/addrepair',
    name: 'addrepair',
    meta: {
      title: '在线报修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/repair/add_repair.vue')
  },
  {
    path: '/repairlist',
    name: 'repairlist',
    meta: {
      title: '报修列表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/repair/repair_history.vue')
  },
  {
    path: '/waitrepair',
    name: 'waitrepair',
    meta: {
      title: '待处理维修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/wait_repair.vue')
  },
  {
    path: '/repair_record',
    name: 'repair_record',
    meta: {
      title: '待处理维修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/repair_record.vue')
  },
  {
    path: '/insiderepair',
    name: 'insiderepair',
    meta: {
      title: '内修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/inside_repair.vue')
  },
  {
    path: '/outsiderepair',
    name: 'outsiderepair',
    meta: {
      title: '外修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/outside_repair.vue')
  },
  {
    path: '/staffwater',
    name: 'staffwater',
    meta: {
      title: '员工水费上传',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/staffwater.vue')
  },
  {
    path: '/adminwater',
    name: 'adminwater',
    meta: {
      title: '舍监水电工水费上传',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/adminwater.vue')
  },
  {
    path: '/adminelec',
    name: 'adminelec',
    meta: {
      title: '舍监水电工电费上传',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/adminelec.vue')
  },
  {
    path: '/waterhistory',
    name: 'waterhistory',
    meta: {
      title: '舍监水电工水费记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/waterhistory.vue')
  },
  {
    path: '/elechistory',
    name: 'elechistory',
    meta: {
      title: '舍监水电工电费记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/elechistory.vue')
  },
  {
    path: '/staffhistory',
    name: 'staffhistory',
    meta: {
      title: '员工水费记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/staffhistory.vue')
  },
  {
    path: '/visitor/index',
    name: 'visitorhome',
    meta: {
      title: '访客首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/index.vue')
  },

  {
    path: '/visitor/bind',
    name: 'visitorbind',
    meta: {
      title: '访客绑定',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/bind.vue')
  },


  {
    path: '/visitor/info',
    name: 'visitorinfo',
    meta: {
      title: '访客个人信息',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/info.vue')
  },


  {
    path: '/visitor/infoedit',
    name: 'visitorinfoedit',
    meta: {
      title: '访客个人信息修改',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/info_edit.vue')
  },


  {
    path: '/visitor/prebook',
    name: 'prebook',
    meta: {
      title: '访客预约单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/pre_book_new.vue')
  },
  {
    path: '/visitor/staffindex',
    name: 'staffindexhome',
    meta: {
      title: '员工首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_list.vue')
  },

  
  {
    path: '/visitor/visit',
    name: 'visited',
    meta: {
      title: '员工邀约单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_new.vue')
  },
  {
    path: '/visitor/preshow',
    name: 'visitorshow',
    meta: {
      title: '预约单查看',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/pre_book_show.vue')
  },
  {
    path: '/visitor/visitshow',
    name: 'visitorvisitshow',
    meta: {
      title: '邀约单查看',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_show.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
